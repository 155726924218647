<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <!-- add Filters -->
      <!-- <template #addFilters>
          <v-card v-if="authUser.isAdmin">
            
          </v-card>
        </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <!-- <template #actionModals>
      </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      // module_name: "csmsChargeStations", //vuex module name which define in Store Index
      permissions: {},
      //
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "upcomingMaintenance/getLoading",
      meta: "upcomingMaintenance/getMeta",
      list: "upcomingMaintenance/getList",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "vehicle_id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "vehicle_variant_name",
        },
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          showAdmin: true,
        },
        ...(this.authUser.user_nature == "fleet"
          ? [
              {
                text: this.$t("manufacturer"),
                align: "left",
                sortable: false,
                value: "tenant_name",
              },
            ]
          : []),
        ...(this.authUser.user_nature !== "fleet"
          ? [
              {
                text: this.$t("alloted"),
                align: "left",
                sortable: false,
                value: "alloted",
              },
            ]
          : []),
        {
          text: this.$t("last_service_date"),
          align: "left",
          sortable: false,
          value: "last_service_date",
        },
        {
          text: this.$t("maintenance_service_name"),
          align: "left",
          sortable: false,
          value: "maintenance_service_name",
        },
        {
          text: this.$t("service_details"),
          align: "left",
          sortable: false,
          value: "service_details",
        },
        {
          text: this.$t("current_odometer_reading"),
          align: "left",
          sortable: false,
          value: "latest_odometer_reading",
        },
        {
          text: this.$t("next_mileage_reading"),
          align: "left",
          sortable: false,
          value: "next_mileage_reading",
        },
        {
          text: this.$t("next_service_date"),
          align: "left",
          sortable: false,
          value: "next_service_date",
        },
        {
          text: this.$t("created_by"),
          align: "left",
          sortable: false,
          value: "created_by_name",
        },
        ...(this.isView || this.isEdit || this.isDelete
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
              },
            ]
          : []),
      ];

      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      return ["single_vehicle", "customer"];
      // if (this.authUser.isAdmin) {
      // } else {
      //   return [];
      // }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "upcoming_vehicle_maintenance",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },

  methods: {
    async fetchData() {
      const params = { ...this.options };

      await this.$store
        .dispatch("upcomingMaintenance/list", params)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },
  },
};
</script>
